import React from "react"
import { Grid } from "semantic-ui-react"

import LinkExternal from "../link-external"

export default ({ emails }) => {
  return (
    <Grid columns={2} divided>
      {emails.map(email => {
        let subjectQuery = email.subject
          ? `?subject=${encodeURIComponent(email.subject)}`
          : ``
        return (
          <Grid.Row key={email.name}>
            <Grid.Column textAlign="right">
              <LinkExternal href={`mailto:${email.email}${subjectQuery}`}>
                {email.name}
              </LinkExternal>
            </Grid.Column>
            <Grid.Column textAlign="left">{email.description}</Grid.Column>
          </Grid.Row>
        )
      })}
    </Grid>
  )
}
