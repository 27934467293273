import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Container, Header, List, Segment } from "semantic-ui-react"

import { StyledContainer } from "../components/core"
import Layout from "../components/layout-desktop"
import SEO from "../components/seo"

import ContactEmailsGrid from "../components/contact/contact-emails-grid"

const contactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      contactDetails: contactJson {
        address {
          address1
          address2
          address3
          city
          postcode
          country
        }
        phone
        emails {
          name
          description
          email
          subject
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Contact SixEye" description="Contact details for SixEye" />
      <Header as="h1" textAlign="center">
        Contact SixEye
      </Header>
      <StyledContainer text>
        <ContactEmailsGrid emails={data.contactDetails.emails} />
      </StyledContainer>

      <Container text>
        You can <Link to="/subscribe/">subscribe to our mailing list</Link> to
        get the latest news and updates from us.
      </Container>

      <Segment basic secondary>
        <Container text>
          <List>
            <List.Item>
              <List.Icon name="mail" />
              <List.Content>
                <List.Header>SixEye Limited</List.Header>
                <List.List>
                  <List.Item>{data.contactDetails.address.address1}</List.Item>
                  {(data.contactDetails.address.address2.length > 0) && (
                    <List.Item>{data.contactDetails.address.address2}</List.Item>
                  )}
                  {(data.contactDetails.address.address3.length > 0) && (
                    <List.Item>{data.contactDetails.address.address3}</List.Item>
                  )}
                  <List.Item>
                    {data.contactDetails.address.city}{" "}
                    {data.contactDetails.address.postcode}
                  </List.Item>
                  <List.Item>{data.contactDetails.address.country}</List.Item>
                </List.List>
              </List.Content>
            </List.Item>
            <List.Item icon="phone" content={data.contactDetails.phone} />
          </List>
        </Container>
      </Segment>
    </Layout>
  )
}
export default contactPage;
